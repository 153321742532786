<template>
  <div class="news-list">
    <container class="pt-4 pb-4 pt-lg-6 pb-lg-8">
      <div class="row">
        <div v-if="publications" class="col-lg-6 col-12">
          <div class="h2 mb-lg-4">
            {{ $texts('new_publications', 'Neue Publikationen') }}
          </div>
          <ul>
            <li
              v-for="(publication, key) in publications"
              :key="key"
              class="entry"
            >
              <figure v-if="publication.book_id" class="snippet-image">
                <div class="book-cover">
                  <DrupalLink class="" :to="publication.url">
                    <img :src="publication.cover_image" alt="" title="" />
                  </DrupalLink>
                </div>
              </figure>
              <div class="font-weight-semibold">
                {{ formattedUnixDate(publication.sort_date || 0) }}
              </div>
              <DrupalLink class="" :to="publication.url">
                <div class="title">
                  {{ publication.title }}
                </div>
              </DrupalLink>
              <p class="teaser">
                {{ publication.type_name }} -
                <DrupalLink
                  v-if="publication.author_url"
                  class=""
                  :to="publication.author_url"
                  >{{ $texts('author', 'Autor') }}:
                  {{ publication.author_name }}
                </DrupalLink>
              </p>
            </li>
          </ul>
        </div>
        <div
          v-if="newsList.length > 0"
          class="col-lg-5 offset-lg-1 col-12 d-none d-lg-block"
        >
          <div class="h2 mb-lg-4">{{ $texts('news', 'News') }}</div>
          <ul>
            <li v-for="news in newsList" :key="news.nid" class="entry">
              <figure v-if="newsImageUrl(news)" class="snippet-image">
                <div class="book-cover">
                  <DrupalLink class="" :to="news?.url?.path">
                    <img :src="newsImageUrl(news)" alt="" title="" />
                  </DrupalLink>
                </div>
              </figure>
              <div class="font-weight-semibold">
                {{ news?.date?.value.toLowerCase() }}
              </div>
              <DrupalLink class="" :to="news?.url?.path">
                <div class="title">{{ news.title }}</div>
                <p v-if="news.teaser" class="teaser">{{ news.teaser }}</p>
              </DrupalLink>
            </li>
          </ul>
        </div>
      </div>
    </container>
  </div>
</template>

<script setup lang="ts">
// Props
import type { NodeNewsFragment, PubdocFragment } from '#graphql-operations'

defineProps({
  newsList: {
    type: Array as PropType<NodeNewsFragment[]>,
    default: () => [],
  },
  publications: {
    type: Array as PropType<PubdocFragment[]>,
    default: () => [],
  },
})

const { $texts } = useNuxtApp()
const language = useCurrentLanguage()

// Methods
const newsImageUrl = function (news: NodeNewsFragment) {
  if (news && news.media && news.media.image && news.media.image.url) {
    return news.media.image.url.url
  }
}
const formattedUnixDate = function (date: number) {
  return new Date(date * 1000).toLocaleDateString(language.value, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}
</script>

<style scoped lang="scss">
@import '~/assets/scss/variables';
.news-list {
  background: $gray-300;
  @include media-breakpoint-up(lg) {
    background: linear-gradient(to left, $gray-400 50%, $gray-300 50%);
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 0;
      list-style: none;
      margin-bottom: 1rem;
    }
  }

  .title {
    margin-bottom: 0.5rem;
  }

  .teaser {
    font-size: 1rem;
  }
}
</style>
