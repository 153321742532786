<template>
  <div class="home">
    <div class="home-hero">
      <container>
        <div class="home-hero-content">
          <h1 class="h1">{{ globalConfig?.title }}</h1>
          <p class="lead">{{ globalConfig?.subTitle }}</p>
        </div>
        <div class="home-hero-background">
          <img
            src="/home-eb.jpg"
            alt="Ediciones Bíblicas"
            title="Ediciones Bíblicas"
          />
        </div>
      </container>
    </div>
    <news-list-mobile v-if="frontpage" :news-list="frontpage.newsList" />
    <news-list
      v-if="frontpage"
      :news-list="frontpage.newsList"
      :publications="frontpage.publications"
    />
    <buena-semilla v-if="frontpage" :calendar="frontpage.calendar" />
    <container class="pt-4 pb-4 pt-lg-6 pb-lg-8">
      <h2 class="h3 mb-3">{{ node.title }}</h2>
      <div
        v-if="node.body"
        class="home-content content"
        v-html="node.body"
      ></div>
    </container>
  </div>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'
import type {
  NodePageFragment,
  CalendarEntryFragment,
} from '#graphql-operations'

defineOptions({
  name: 'Homepage',
})

definePageMeta({
  name: 'home',
})

setBreadcrumbLinks()
const globalConfig = await useGlobalConfig()

provide('isFrontPage', true)

const nuxtRoute = useRoute()

const currentDate = computed(() => {
  const d = new Date()
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
})

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, async () => {
  return useGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => v.data)
})

// Get the data.
const { data: frontpage } = await useAsyncData(async () => {
  return useGraphqlQuery('frontPage', {
    date: currentDate.value,
  }).then((v) => {
    return {
      newsList: v.data.newsList?.items || [],
      publications: v.data.publications?.documents || [],
      calendar:
        (v.data.calendar?.items?.shift() as CalendarEntryFragment) || null,
    }
  })
})

// // Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)
await renderPageDependencies()
</script>
