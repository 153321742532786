<template>
  <div v-if="calendar" class="buena-semilla">
    <container class="pt-4 pb-4 pt-lg-6 pb-lg-8">
      <div>
        <div class="h2 mb-4">La Buena Semilla</div>
        <div class="media d-flex">
          <div class="flex-shrink-0">
            <img alt="La Buena Semilla" class="me-4" src="/buenasemilla.png" />
          </div>

          <div class="media-body flex-grow-1 ms-3">
            <div v-if="calendar.verse1" class="wrapper">
              <span class="verse">{{ calendar.verse1 }}</span>
              <p v-if="calendar.passage1" class="passage">
                {{ calendar.passage1 }}
              </p>
            </div>
            <div v-if="calendar.verse2" class="wrapper">
              <span class="verse">{{ calendar.verse2 }}</span>
              <p v-if="calendar.passage2" class="passage">
                {{ calendar.passage2 }}
              </p>
            </div>
            <div v-if="calendar.verse3" class="wrapper">
              <span class="verse">{{ calendar.verse3 }}</span>
              <p v-if="calendar.passage3" class="passage">
                {{ calendar.passage3 }}
              </p>
            </div>
            <p class="text-start text-md-end mt-4">
              <a
                :href="calendar.link?.uri?.path"
                target="_blank"
                class="btn btn-primary"
              >
                {{ $texts('read_calendar', 'Kalender lesen') }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </container>
  </div>
</template>

<script setup lang="ts">
import type { CalendarEntryFragment } from '#graphql-operations'

defineProps({
  calendar: Object as PropType<CalendarEntryFragment>,
})

const { $texts } = useNuxtApp()
</script>

<style scoped lang="scss">
@import '~/assets/scss/variables';
.buena-semilla {
  background: $gray-200;

  .media {
    img {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
        max-width: 150px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 200px;
      }
    }
  }

  .wrapper {
    margin-bottom: 0.5rem;
  }

  .verse,
  .passage {
    font-family: 'Crimson Pro', serif;
  }
  .verse {
    font-size: 1.2em;
  }
  .passage {
    font-style: italic;
  }
}

img {
  width: 100%;
}
</style>
